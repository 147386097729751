import axios from "axios";
import Cookies from "js-cookie";
import { getApiURL } from "./baseURL";
import { jwtDecode } from "jwt-decode";


const cookies = Cookies.withConverter({
    read: function (value, name) {
        if (name === 'token') {
            return value
        }
        return Cookies.converter.read(value, name)
    },
    write: function (value, name) {
        if (name === 'token') {
            return value
        } else {
            return value
        }
    }
})

const client = axios.create({
    baseURL: getApiURL()
});


export const request = ({ ...options }) => {
    const onSuccess = (response) => {

        if (response.headers.has('x-access-token')) {
            const token = response.headers['x-access-token']

            cookies.set("token", token, { expires: new Date(new Date().getTime() + 365 * 1440 * 60 * 1000) });

            const decoded = jwtDecode(token.split(':')[0])

            if (decoded) {
                cookies.set("user", JSON.stringify(decoded), { expires: new Date(new Date().getTime() + 365 * 1440 * 60 * 1000) });
            }
        }

        return response;
    };
    const onError = (error) => {
        if (error?.response?.status === 401) {
            cookies.remove('token');
            cookies.remove('user');
            cookies.remove('refresh_token')
            window.location.replace('/login')
        }
        else {
            window.location.replace('/404')
        }
        return error;
    };
    client.defaults.headers['app-version'] = '100.0'
    client.defaults.headers['ngrok-skip-browser-warning'] = '100.0'

    // if (cookies.get("token") != undefined
    //     && internal
    //     && client.defaults.headers.Authorization == undefined) {
    //     client.defaults.headers.Authorization = `${cookies.get("token")}`
    // }

    // if (cookies.get("refresh_token") != undefined
    //     && internal
    //     && client.defaults.headers['x-refresh-token'] == undefined) {
    //     client.defaults.headers['x-refresh-token'] = `${cookies.get("refresh_token")}`
    // }

    client.defaults.headers.Authorization = `${cookies.get("token")}`
    client.defaults.headers['x-refresh-token'] = `${cookies.get("refresh_token")}`

    return client(options).then(onSuccess).catch(onError);
};
