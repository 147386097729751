import React, { useContext } from "react";
import { useTable } from "react-table";
import PropTypes from "prop-types";
import { CommentModal } from "../comment-modal/commentModal";
import { StatusDropdown } from "../status-dropdown/status-dropdown";
import { NextCallbackModal } from "../nexcallback/nextcallback-modal";
import { CallBackContext } from "../../../contexts";
import { formatDateTime } from "../../../utils/DatetimeFormat-table";
import { PrescriptionModal } from "../../../components/prescription-modal/prescriptionModal";


export default function TableLayout() {
    const { data, columns } = useContext(CallBackContext);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })

    function renderCellComponent(cell) {
        const { Header } = cell.column;
        const { original } = cell.row;

        switch (Header) {
            case "Prescription":
                return <td className="px-2 text-left w-fit" key={cell.getCellProps().key}><PrescriptionModal prescription={original.links} /></td>;
            case "Comment":
                return <td className="px-2 text-left" key={cell.getCellProps().key}><CommentModal callback={original} /></td>;
            case "Status":
                return <td className="px-2 text-left" key={cell.getCellProps().key}><StatusDropdown callback={original} /></td>;
            case "NextCallback":
                return <td className="px-2 text-left" key={cell.getCellProps().key}><NextCallbackModal callback={original} /></td >;
            case "Created On":
                return <td className="px-2 text-left" key={cell.getCellProps().key}>{formatDateTime(original.createdAt)}</td >;
            case "Name":
                return <td className="pl-3 py-4 text-left text-lg font-sans font-size-xl" key={cell.getCellProps().key} {...cell.getCellProps()}> <h1>
                    <b className="text-lg">
                        {original.mapUserPatient.Patient.name} : &nbsp;
                    </b>
                    <span className="text-elm-500 text-lg">
                        {original.mapUserPatient.Patient.contact_no}
                    </span>
                    {
                        original.mapUserPatient.User.id != original.mapUserPatient.Patient.id &&
                        <>
                            <b className="text-blue-500 text-lg">
                                &nbsp; {[original.mapUserPatient.relationship === "OLD" ? "" : original.mapUserPatient.relationship.toLowerCase()]}
                            </b>
                            <br />
                            <b className="text-lg">
                                {original.mapUserPatient.User.name}:
                            </b>
                            &nbsp;
                            <span className="text-elm-500 text-lg">
                                {original.mapUserPatient.User.contact_no}
                            </span>
                        </>
                    }
                </h1></td>
            case "#":
                return (
                    <td
                        {...cell.getCellProps()}
                        className="py-4 text-left pl-3 font-sans cursor-pointer hover:text-black text-elm-500 font-medium"
                        key={original.id}>
                        {cell.render("Cell")}
                    </td>
                )
            default:
                return <td className="px-2 text-left" key={cell.getCellProps().key
                } {...cell.getCellProps()}> {cell.render("Cell")}</td >;
        }
    }
    return (

        <div className="block bg-white rounded-2xl shadow-soft-xl w-[100%] bg-[white] mt-4 p-2 px-4  dark:hover:bg-gray-700">
            <table {...getTableProps()} className="table-auto w-[100%] font-sans">
                <thead className="text-center border-b-2 border-gray-50 h-16">
                    {headerGroups.map((headerGroup, id) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={id} className="text-left border-b-2 border-gray-100">

                            {headerGroup.headers.map((column, id) => (
                                <th {...column.getHeaderProps()} key={id} className="font-sans text-left pl-3">{column.render("Header")}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, id) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={id} className="hover:bg-white px-2 border-b text-left border-gray-50 pl-3 font-sans">
                                {row.cells.map((cell, id) => {
                                    return <React.Fragment key={id}>
                                        {renderCellComponent(cell)}
                                    </React.Fragment>
                                })}

                            </tr>
                        );
                    })}
                </tbody>
            </table >
        </div>
    )

}
TableLayout.propTypes = {
    columns: PropTypes.any,
    data: PropTypes.any,
    refetch: PropTypes.any


};




