import React, { useEffect } from "react";
import morning from "../../../../assets/morning.png";
import evening from "../../../../assets/evening.png";
import afternoon from "../../../../assets/afternoon.png";
import night from "../../../../assets/night.png";
import { Rows } from "./rows";
import PropTypes from "prop-types";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
import DrxQr from "../drxQr";


function HorizontalTable(props) {
    const { getDates, rows, drName, patientName, count, prescriptionValues, index, wbn, downloadAllSmallDrx, flipDownloadSmallDrx } = props;
    const printRef = React.useRef();
    let divName = `drx-table-3-${index}`;
    const { orderId } = useParams()

    const generatePNG = async () => {
        const divToDisplay = document.getElementById(divName);
        const canvas = await html2canvas(divToDisplay);
        const data = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        if (typeof link.download === "string") {
            link.href = data;
            link.download = `SmallBox_${patientName}_${orderId}.png`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
        if (downloadAllSmallDrx) {
            flipDownloadSmallDrx()
        }
    };

    useEffect(() => {
        if (downloadAllSmallDrx) {
            generatePNG();
        }
    }, [downloadAllSmallDrx])
    return (
        <div className="p-5">
            <div
                id={divName}
                ref={printRef}
                style={{
                    maxHeight: "900px",
                    overflow: "hidden",
                    maxWidth: "1020px",
                    minWidth: "1020px",
                    border: "3px solid black"
                }}>
                <table className="h-auto border-none" style={{ width: "1020px" }}>
                    <thead>
                        <tr className="bg-[#dbdbdb]"
                            style={{
                                width: "1020px",
                                height: "150px",
                                maxHeight: "150px",
                            }}>
                            <th>
                                <div className="text-left px-4 flex flex-col gap-2">
                                    <h2 className="text-4xl font-sans mb-1" contentEditable={true}> {patientName}</h2>
                                    <h2 className="text-2xl font-sans">
                                        {getDates.StartDate}- {getDates.EndDate}
                                    </h2>
                                    <h2 className="text-xl  text-gray-600 capitalize font-sans">
                                        {drName}
                                    </h2>
                                </div>
                            </th>
                            <th>
                                <div className="flex flex-col items-center">
                                    <img
                                        src={morning}
                                        alt="morning"
                                        className="object-fit w-[67%] mb-1"
                                    />
                                    <h2 className="text-lg">Morning</h2>
                                </div>
                            </th>
                            <th>
                                <div className="flex flex-col items-center">
                                    <img
                                        src={afternoon}
                                        alt="afternoon"
                                        className="object-fit w-[63%] mb-1"
                                    />
                                    <h2 className="text-lg">Afternoon</h2>
                                </div>
                            </th>
                            <th>
                                <div className="flex flex-col items-center">
                                    <img
                                        src={evening}
                                        alt="evening"
                                        className="object-fit w-[77%] mb-1"
                                    />
                                    <h2 className="text-lg">Evening</h2>
                                </div>
                            </th>
                            <th>
                                <div className="flex flex-col items-center">
                                    <img
                                        src={night}
                                        alt="night"
                                        className="object-fit w-[62%] mb-1"
                                    />
                                    <h2 className="text-lg">Night</h2>
                                </div>
                            </th>
                            <th style={wbn ? { width: "150px" } : {}}>
                                <div className="flex  justify-center items-end h-[8vh] mt-12">
                                    <h2 className="text-lg">Qty</h2>
                                </div>
                            </th>
                            {wbn ? null :
                                <th className="p-2" style={wbn ? { width: "150px" } : {}}>
                                    <div className="items-center justify-center">
                                        <div className="flex flex-col items-center ">
                                            <DrxQr id={orderId} />
                                            <h2 className="text-lg">Details</h2>
                                        </div>
                                    </div>
                                </th>
                            }
                        </tr>
                    </thead>

                    <tbody>
                        {/* {rows.map((row, index) => (
                            <Rows {...row} key={index} />
                        ))} */}
                        {prescriptionValues?.rows.map((med, index) => (
                            <Rows
                                {...med}
                                prescription={med}
                                key={index}
                                wbn={wbn}
                                length={count}
                                height={825}
                                StartDate={getDates?.StartDate}
                                EndDate={getDates?.EndDate}
                                qty={() => {
                                    if (rows[index].Duration < 1) {
                                        return med.Qty;
                                    }
                                    return (
                                        rows[index].time.length *
                                        rows[index].Duration *
                                        rows[index].item.quantity
                                    );
                                }} />
                        ))}
                        {count < 7 && (
                            <>
                                {Array.from({ length: 7 - count }).map((_, i) => (
                                    <tr
                                        className="border-2 border-[#e5e7eb]"
                                        style={{ height: `100px` }}
                                        key={i}>
                                        <td className={`w-[270px] border-2 border-[#e5e7eb] pb-6`}>
                                            <div className="flex items-start px-4 flex-col justify-start text-left"></div>
                                        </td>

                                        <td className="w-[200px] border-2 border-[#e5e7eb]"></td>
                                        <td className="w-[200px] border-2 border-[#e5e7eb]"></td>
                                        <td className="w-[200px] border-2 border-[#e5e7eb]"></td>
                                        <td className="w-[200px] border-2 border-[#e5e7eb]"></td>

                                        <td className="w-[50px] border-2 border-[#e5e7eb]">
                                            <div className="flex items-center w-full justify-center">
                                                <div className="p-3 text-left text-2xl px-4 w-16 leading-3"></div>
                                            </div>
                                        </td>
                                        {
                                            wbn ? null :
                                                <td className="w-[200px] border-2 border-[#e5e7eb]">
                                                    <div className="flex items-start w-full flex-col justify-center px-4">
                                                        <div className="text-left text-sm flex flex-col">
                                                            <tr className=""></tr>
                                                            <tr className=""></tr>
                                                            <tr className=""></tr>
                                                        </div>
                                                    </div>
                                                </td>
                                        }
                                    </tr>
                                ))}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
            <button
                onClick={generatePNG}
                className="mt-6 mb-6 p-2 bg-[#28A48B] text-white font-semibold px-6 rounded-2xl"
                style={{ color: "white" }}>
                Download SmallBox
            </button>
        </div>
    );
}

HorizontalTable.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    rows: PropTypes.array,
    drName: PropTypes.string,
    patientName: PropTypes.string,
    total: PropTypes.any,
    ref: PropTypes.any,
    count: PropTypes.any,
    prescriptionValues: PropTypes.any,
    index: PropTypes.any,
    getDates: PropTypes.any,
    wbn: PropTypes.bool,
    downloadAllSmallDrx: PropTypes.bool,
    flipDownloadSmallDrx: PropTypes.func,
};
export { HorizontalTable };
