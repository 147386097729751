import React from "react";
import "./App.scss";
import { RouterContaner } from "./navigation";
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import MesssageDialog from "./layouts/Dashboard/components/toast";

function App() {
    return (
        <div className="App" style={{ fontFamily: "CabinetGrotesk" }}>
            <MesssageDialog style={{ zIndex: 9999 }} />
            <RouterContaner />
        </div >
    );
}

export default App;
